@import "../../../style/sass/variables.scss";

.navItem-container-res {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  min-height: 2.4rem;
  padding: 0.6rem;
  align-items: center;
  color: $grey-text;

  &:hover {
    background-color: $grey-hover;
    color: $black-text;
    border-radius: 0.3rem;
    cursor: pointer;
  }

  .navItem-subcontainer-res {
    display: flex;
    flex-direction: row;
    gap: 1rem;

    .navItem-res{
      display: flex;
      padding: 0;
      height: 1.2rem;
      width: 1.2rem;
    }

    .navItem-text-res {
      font-weight: 500;
    }
  }
  .navItem-sidebar-icons-res{
    display: flex;
    height: 1.1rem;
    width: 1.1rem;
  }
}
