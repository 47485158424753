@import "../../../style/sass/variables.scss";



.donut-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;


  .donut-circle {
    width: 12rem;
    height: 12rem;
  }
  
  .donut-head {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .donut-heading {
      font-size: 1.2rem;
      font-weight: 400;
      color: $black-text;
    }

    .donut-see-all {
      font-size: 0.85rem;
      font-weight: 600;
      cursor: pointer;
      color: $grey-text;
      text-decoration: underline;
    }
  }
  .donut-body {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    height: 14.5rem;

    @media screen and (max-width: 600px) {
      flex-direction: column-reverse;
      min-height: 20rem;
      .donut-circle {
        width: 6rem;
        height: 6rem;
      }
    }

    .donut-expense {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      padding: 0.4rem;
      overflow: scroll;
    }
    .donut-chart {
      width: 80%;
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;
      @media screen and (max-width: 600px) {
        flex-direction: column-reverse;
        width: 100%;
        height: 25vh;
      }
    }
  }
}
