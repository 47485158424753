.login-logo{
    font-size: 2.1rem;
    font-weight: 900;
}

.passwordEye{
    position: absolute;
    right: 10px;
    top: 40px;
    cursor: pointer;
}