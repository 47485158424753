@import "../../../style/sass/variables.scss";
.profile-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  .profile-head {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .profile-heading {
      .profile-heading-text {
        font-size: 1.1rem;
        font-weight: 500;
        font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
      }
    }
    .profile-heading-btn {
      display: flex;
      gap: 1rem;
      flex-direction: row;
      height: 2.2rem;

      .btn1 {
        background-color: #b0d3ff;
        color: black;
        border: none;
        @media screen and (max-width: 410px) {
            display: none;
         }

        :hover {
          background-color: #b0d3ff;
          border: none;
        }
      }
    }
  }
  .profile-person-info {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    padding: 1rem;
    background-color: white;
    border-radius: $border-radius;
    border: 1.5px solid $grey-line;
    @media screen and (max-width: 699px) {
        flex-direction: column;
      }

    .personal-info {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 0.1rem;
      padding: 1rem;
      @media screen and (max-width: 699px) {
        padding: 0rem;
     }

      .btn3 {
        width: 9.5rem;
        font-size: 0.75rem;
        text-decoration: none;
      }

      .personal-info-heading {
        font-size: 1.1rem;
        font-weight: 550;
        margin-bottom: 1rem;
      }
      .personal-info-para {
        font-size: 0.8rem;
        font-weight: 450;
        font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
        margin-bottom: 1rem;
      }
      .personal-info-input {
        display: flex;
        flex-direction: column;
        gap: 0.3rem;
        padding: 0.5rem;

        .input-label-text {
          font-size: 0.9rem;
        }
        .input-fields {
          height: 2rem;
        }
      }
    }
  }
  .profile-person-addr {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    padding: 1rem;
    border-radius: $border-radius;
    border: 1.5px solid $grey-line;
    background-color: white;

    @media screen and (max-width: 699px) {
        flex-direction: column;
        gap: 0rem;
      }

    .personal-info {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 0.1rem;
      padding: 1rem;

      @media screen and (max-width: 699px) {
         padding: 0rem;
      }

      .btn3 {
        width: 9.5rem;
        font-size: 0.75rem;
        text-decoration: none;
      }
      .personal-info-heading {
        font-size: 1.1rem;
        font-weight: 550;
        margin-bottom: 1rem;
      }
      .personal-info-para {
        font-size: 0.8rem;
        font-weight: 450;
        font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
        margin-bottom: 1rem;
      }
      .personal-info-input {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        padding: 0.5rem;

        .input-label-text {
          font-size: 0.9rem;
        }
        .input-fields {
          height: 2rem;
        }
      }
    }
  }
}
