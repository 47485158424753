@import "../../style/sass/variables.scss";
// ::-webkit-scrollbar {
//   display: none;
// }

.layout-container {
  display: flex;
  flex-direction: row;
  max-height: 100vh;

  @media screen and (max-width: 430px) {
    flex-direction: column;
    gap:1rem;
    }

  .layout-sidebar {
    border-right: 1.5px solid $grey-line;
    display: subgrid;
    //position: fixed;
  }
  .layout-right {
    display: flex;
    width: 100vw;
    background-color: $grey-background;
    flex-direction: column;
    padding: 1.5rem;
    gap: 1rem;
    overflow-x: hidden;
    overflow-y: scroll ;
    
    .layout-header{
      
    }
    .layout-children{
      overflow-y: scroll;
      overflow-x: hidden;
      height: 94%;
    }
  }
}


$small-screen: 576px;
$medium-screen: 768px;

@media (max-width: $small-screen) {

  .layout-container {
    .layout-right {
      padding: 1rem;
       @media screen and (max-width: 430px) {
        margin-top: 3rem;
       }
    }
  }
}
