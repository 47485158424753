@import "../../style/sass/variables.scss";
.home-container {
  display: flex;
  flex-direction: column;
  gap: 0.8rem;

  .tiles-container {
    display: flex;
    flex-direction: row;
    gap: 2rem;
    justify-content: space-between;

    @media screen and (max-width: 430px) {
      gap: 1rem;
    }
  }

  .graph-container {
    background-color: white;
    border: 1px solid $grey-line;
    height: 16rem;
    padding: 1rem;
    padding-bottom: 2rem;
    border-radius: $border-radius;

    @media screen and (max-width: 700px) {
      display: none;
    }
  }
  .chart-container {
    display: flex;
    flex-direction: row;
    gap: 1rem;

    @media screen and (max-width: 900px) {
      flex-direction: column;
    }

    .chart-container-left {
      background-color: white;
      border: 1px solid $grey-line;
      border-radius: $border-radius;
      padding: 1rem;
      width: 100%;
    }
    .chart-container-right {
      border: 1px solid $grey-line;
      border-radius: $border-radius;
      background-color: white;
      height: 20rem;
      padding: 1rem;
      display: flex;
      flex-direction: column;
      gap: 1rem;
      width: 80%;
      @media screen and (max-width: 900px) {
        width: 100%;
      }

      .chart-container-right-heading {
        font-size: 1.2rem;
        font-weight: 400;
        color: $black-text;
      }

      .chart-container-right-card-list {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        overflow: scroll;
      }
    }
  }
}
