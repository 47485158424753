@import "../../style/sass/variables.scss";

.sidebar-container {
  display: flex;
  flex-direction: column;
  width: 17vw;
  padding: 1rem;
  box-sizing: border-box;
  min-height: 100vh;
  justify-content: space-between;
  

  @media screen and (max-width: $extra-large-screen) {
    padding: 1rem;
    width: 20vw;
  }
  @media screen and (max-width: $large-screen) {
    margin-right: 1.2rem;
  }
  @media screen and (max-width: 1000px) {
    padding: 0.5rem;
    margin-right: 1.2rem;
  }
  @media screen and (max-width: 950px) {
    padding: 0.4rem;
    width: 11rem;
  }
  @media screen and (max-width: 799px) {
    padding: 0;
    width: 6vw;
    align-items: center;
    padding-top: 1rem;
  }
  @media screen and (max-width: 700px) {
    padding: 0;
    width: 8vw;
    align-items: center;
    padding-top: 1rem;
  }
  .container-top {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    .logo-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      @media screen and (max-width: $small-screen) {
        flex-direction: row;
      }

      .close-icon {
        display: none;
      }

      .humburg {
        display: none;
      }

      .action-btn {
        cursor: pointer;
        margin-left: 0.5rem;
        z-index: 10;
        display: flex;
        stroke: black;
        height: 1.5rem;
        width: 1.5rem;

        @media screen and (max-width: 799px) {
          cursor: none;
          pointer-events: none;
        }
      }

      .logo-heading {
        font-weight: 850;
        align-items: center;

        @media screen and (max-width: 950px) {
          font-size: 0.9;
        }
        @media screen and (max-width: 799px) {
          display: none;
        }
      }
    }
  }
  .container-bottom {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 1rem 0 1rem 0;
    border-top: 1.5px solid $grey-line;

    .bottom-text {
      @media screen and (max-width: 799px) {
        display: none;
      }
    }

    .bottom-element {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      padding: 0 0 0 0.5rem;

      @media screen and (max-width: 799px) {
        padding: 0;
      }
    }
  }
}

.side-mobile-contaner {
  @media screen and (max-width: 430px) {
    display: none;
  }
}
.logo-container {
  display: none;
  @media screen and (max-width: 430px) {
    display: flex;
    flex-direction: row;
    width: 100vw;
    z-index: 5;
    height: inherit;
    align-items: center;
    position: fixed;
    background-color: white;
    gap: 1rem;
    padding: 0.6rem;
    padding-left: 1.2rem;
  }
  .humburg {
    cursor: pointer;
    height: 1.4rem;
    display: flex;
    width: 1.5rem;
  }
  .logo-heading {
    font-size: 1.6rem;
    margin-left: 0.5rem;
    font-weight: 900;
  }
}
