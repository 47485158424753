$primary: #2C96EA; 
$secondary: #272727;
$secondary-dark: #F1F1F1;
$black-text: #000;
$white-text: #fff;
$grey-text:#808080;
$grey-line:#D1D5DB;
$grey-hover:#F3F4F6;
$white-baclground:#ffffff;
$grey-background:#F3F4F6;
$border-radius:0.4rem;


//Screen break points
$small-screen:576px;
$medium-screen:768px;
$large-screen:992px;
$extra-large-screen:1200px;