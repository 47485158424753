@import "../../style/sass/variables.scss";
.donut-expense-item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 15px;
    
    .left-item {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      gap: 0.5rem;

      .left-item-icons {
        height:25px;
        width: 25px;
      }
      .left-item-text {
        font-size: 1rem;
        color: $grey-text;
        font-weight: 500;
        height: 25px;
      }
    }
    .right-item {
      .right-item-text {
        font-size: 1.0rem;
        color: $black-text;
        font-weight: 450;
        height: 35px;
      }
    }
  }