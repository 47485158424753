.left-swipe {
    background: linear-gradient(to left, white 0%, #ef4444);
    margin-top: 1rem;
  }
.right-swipe{
    background: linear-gradient(to right, white 0%, #10b981);
    margin-top: 1rem;
  }

  .expense-sub-container{
    @media screen and (max-width:699px) {
      overflow-x: scroll;
      flex-wrap: nowrap;
      border: none;
      flex-direction: row;
    }
  }
