.spinner-container {
  position: absolute;
  left: 50%;
  top: 40%;


  @media screen and (max-width: 576px) {
    left: 47%;
  }
}
