@import "../../style/sass/variables.scss";


.type {
    background-color: #FFF2CC;
    padding: 0.3rem;
    text-align: center;
    border-radius: 1rem;
    display: flex;
    align-items: center;
    p{
        color: #FFA500;
        font-size: .8rem;
    }
}

.scroll-y-container {
    height: 28rem;
    overflow:scroll;
}

.scroll-y-container-acc{
  height: 35.1rem;
  overflow:scroll;
}

.category-text {
    text-align: right;
    margin-top: 0.3rem;
}

.action-btn {
    width: 30%;
}

.filter-desk {
    // display: flex;
    // justify-content: space-between;s

    .filter-item {
    
        text-align: center;
        padding: 0.6rem;
        margin-right: 1rem;
        display: flex;
            align-items: center;
            img {
                margin-right: 0.6rem;
            }
    }

    .active {
        border-bottom: 2px solid $primary;
        p {
            font-weight: 600;
        }
    }
}

.filter-mobile  {
    display: flex;
    // justify-content: space-between;s
    align-items: center;
    overflow-x: scroll;
    .filter-item {
    
        text-align: center;
        padding: 0.6rem;
        // margin-right: 1rem;
        width: 10.5rem;
    }

    .active {
        border-bottom: 2px solid $primary;
        p {
            font-weight: 600;
        }
    }

    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
}



.card-heading {
    display: flex;
    justify-content: space-between;
    margin-bottom: 2rem;
    align-items: center;
    p {
        font-size: 1.5rem;
        font-weight: 500;
    }
}


.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  z-index: 9;
}

.back-drop {
  position: fixed;
  top: 0;
  right: 0;
  background-color: #00000045;
  z-index: 8;
  width: 100%;
  height: 100%;
}



.cat-change-loader {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: #0000006e;
  width: 100%;
  height: 100%;
  z-index: 9;
  display: flex;
  justify-content: center;
  align-items: center;
}

.expence-cat-heading {

    display: flex;
    justify-content: space-between;
    align-items: center;
    p {
        font-size: 1.5rem;
    font-weight: 500;
    }
    .expence-count {
        margin-left: 7px;
    }

    .filter-btn {
        display: flex;
        justify-content: end;
    }
}


.see-all {
    margin-top: 2rem;
    display: flex;
    justify-content: space-between;
    .select-all {
        margin-left: 1rem;
        font-size: 1rem;
    }
}

.credit-card {
    margin-top: 1rem;
}

.expence-card {
    margin-top: 1rem;
    overflow-x: scroll;
}


.expence-container-mobile {
    display: none;
  }

  .truncate-text {
    white-space: nowrap;         
    overflow: hidden;           
    text-overflow: ellipsis;     
    max-width: 100%;             
  }

  .mobile-expense-scroll-container {

    .type {
        width: 100%;
    }
  }

  .mobile-credit-scroll-container, .mobile-expense-scroll-container {
    height: 100vh;
    overflow-y: scroll;
  }

  .credit-card-container-mobile {
    display: none;
  }




  .category-container {
    display: flex;
    flex-direction: column;
    align-items: end;
    .type {
        padding: 0 1rem;
    }
}


$small-screen: 576px;

$medium-screen: 768px;

// Write a media query using the variable
@media (max-width: $small-screen) {
  .expence-container-desk {
    display: none;
  }

  .expence-cat-heading {
    p {
        font-size: 1rem;
    }
  }

  .analytics-container {
    width: 60%;
  }

  .filter-desk {
    display: none;
  }

  .expenese-action-btns {
    // display: none;
  }

  .credit-card-container-desk {
    display: none;

  }


  .filter-mobile {
    .filter-item {
      width: fit-content !important;
        display: flex;
            align-items: center;
            img {
                margin-right: 0.6rem;
            }

            p {
              text-wrap: nowrap;
            }
    }
  }


  .credit-card-container-mobile.active {
    display: block;
  }


  .expence-container-mobile.active {
    display: block;

    .filter-btn {
        button {
            background: #B0D3FF;
            border: none;
            padding: 0.7rem 0.5rem;
            display: flex;
            justify-content: center;

            img {
                margin-right: 0.5rem;
            }
        }
    }

    .category-container {
        gap: 2rem;
        display: flex;

        .type {
            // padding: 0.3rem 2rem;
        }
    }

    .last-used {
        display: flex;
        justify-content: space-between
        ;
    }

    .price {
        width: 100%;
    }
  }

  .expence-cat-heading {
    background: white;
    padding: 1rem 0rem 1rem 0rem;
    border-bottom: 1px solid #ccc;
  }

}


@media (min-width: $small-screen) {
    .expence-container-mobile {
      display: none;
    }
  
    .expenese-action-btns {
      display: none;
    }
  
    .credit-card-container-mobile {
      display: none;
    }


  .filter-mobile {
    display: none;
  }

 
  }

@media (max-width: $medium-screen) {
    :root {
        font-size: 85%;
    }
}