@import "../../style/sass/variables.scss";
.sidebar-container-res {
  width: 63vw;
  top: 0;
  z-index: 11;
  background-color: white;
  display: flex;
  padding: 1rem;
  height: 100%;
  position: fixed;
  flex-direction: column;
  justify-content: space-between;

  .head-container{
    display: flex;
    flex-direction: row;
    height: 3rem;
    justify-content: space-between;
    align-items: center;
    padding-left:0.8rem;

    .logo-heading{
      font-size: 1.7rem;
      font-weight: 900;
    }
    .close-icon{
      display: flex;
      width: 2rem;
      height: 2rem;
    }
  }

  .container-top-res {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  .container-bottom-res {
    display: flex;
    margin-left: 0.6rem;
    flex-direction: column;
    gap: 1rem;
    padding: 1.5rem 0 1.5rem 0;
    border-top: 1.5px solid $grey-line;

    .bottom-element-res {
      display: flex;
      flex-direction: column;
      gap: 1rem;
    }
  }
}
