@import "../../style/sass/variables.scss";

.model-container {
  position: absolute;
  top: 3vh;
  left: 29vw;
  height: 90vh;
  padding: 1rem;
  width: 42vw;
  background-color: white;
  z-index: 12;
  //border: 1px solid $grey-line;
  box-shadow: 0rem 1rem 1rem 0.5rem $grey-line;
  border-radius: $border-radius;
  display: flex;
  flex-direction: column;
  gap: 1rem;

  @media screen and (max-width: 1200px) {
    width: 60vw;
    left: 20vw;
  }
  @media screen and (max-width: 840px) {
    width: 72vw;
    left: 14vw;
  }

  @media screen and (max-width: 430px) {
    width: 90vw;
    left: 5vw;
    height: 95vh;
  }

  .model-heading {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;

    .model-close-btn{
        cursor: pointer;
        height: 25px;
        width: 25px;
        border-radius: 2px;
        font-weight: 550;
        text-align: center;
    }
    .model-close-btn:hover{
        font-size: 1.05rem;
    }

    .model-heading-text {
      display: flex;
      flex-direction: column;
      text-align: center;
      margin-left: 33%;

      @media screen and (max-width: 600px) {
        margin-left: 24%;
      }
    
      .main-text {
        font-size: 1.2rem;
        font-weight: 450;
        color: $black-text;
      }
      .sub-text {
        font-size: 0.8rem;
        font-weight: 400;
        color: $grey-text;
      }
    }
  }
  .model-donut-chart {
    display: flex;
    height: 30vh;
    align-items: center;
    justify-content: center;

  }

  .model-expense-list {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    overflow-y: scroll;

    @media screen and (max-width: 600px) {
      flex-direction: column;
      gap: 0rem;
    }

    .model-expense-list-left{
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }
    .model-expense-list-right{
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }
  }
  .model-expense-list-lar {
    display: flex;
    flex-direction: row;
    gap: 2rem;
    justify-content: space-between;
    overflow-y: scroll;

    @media screen and (max-width: 600px) {
      flex-direction: column;
      gap: 0rem;
    }

    .model-expense-list-left{
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }
    .model-expense-list-right{
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }
  }
}
