@import "../../../style/sass/variables.scss";

.navItem-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  min-height: 2.4rem;
  padding: 0.6rem;
  align-items: center;
  color: $grey-text;

  &:hover {
    background-color: $grey-hover;
    color: $black-text;
    border-radius: 0.3rem;
    cursor: pointer;
  }

  .navItem-subcontainer {
    display: flex;
    flex-direction: row;
    gap: 0.8rem;

    .navItem{
      display: flex;
      padding: 0;
      height: 1.2rem;
      width: 1.2rem;
    }

    .navItem-text {
      font-weight: 500;
      @media screen and (max-width: 799px) {
        display: none;
      }
    }
  }

  .navItem-sidebar-icons {
    display: flex;
    height: 1.1rem;
    width: 1.1rem;
    @media screen and (max-width: 950px) {
      display: none;
    }
  }
}
