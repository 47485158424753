@import "../../style/sass/variables.scss";

.tile-container {
  display: flex;
  background-color: white;
  flex-direction: column;
  border: 1.5px solid $grey-line;
  border-radius: $border-radius;
  padding: 0.6rem 1rem;
  width: 100%;

  .tile-name {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: $grey-text;
  }
  .tile-value {
    font-weight: 650;
    font-size: 30px;
    line-height: 36px;
    color: $black-text;
  }
}
