::-webkit-scrollbar {
  display: none;
  scrollbar-width: none;
}

body {
  margin: 0;
  padding: 0;
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}