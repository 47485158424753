.stepper-container {
  min-height: 100vh;
  background-color: #f9fafb;
  align-items: center;
  @media screen and (max-width: 576px) {
    background-color: white;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .stepper-sub-container {
    border: 1px solid #e2e5e9;
    @media screen and (max-width: 576px) {
      border: none;
      padding-top: 1rem;
    }

    .stepper-logo {
      height: 2rem;
      width: 10rem;
    }
    .stepper-form {
    }

    .step-container {
      .form-container{
        @media screen and (max-width: 576px) {
          display: flex;
          flex-direction: column;
          height: 70vh;
          overflow-y: scroll;
          overflow-x: hidden;
        }

        .sub-heading {
          font-size: 0.88rem;
          margin: 0.3rem;
        }

        .occ-container{
          max-height: 50vh;
          overflow: scroll;
          @media screen and (max-width: 576px) {
            max-height:100%;
          }  
        }
      }
      @media screen and (max-width: 576px) {
      }
    }
  }
}
